// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import Breadcrumbs from '../breadcrumbs'
import '../breadcrumbs/style.less'

import NewsPageWrapper from '../news-page-wrapper'

import './style.less'

import formattedDate from '../../methods/formatted-date'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query NewsPageTemplateQuery($routeSlug: String) {
    allNewsJson(filter: { routeSlug: { eq: $routeSlug } }) {
      edges {
        node {
          title
          routeSlug
          published
          source
          contentBlocks {
            type
            content
          }
        }
        next {
          routeSlug
          title
        }
        previous {
          routeSlug
          title
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
class Page extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()
  }

  /** [render description] */
  render() {
    const {
      data: {
        allNewsJson: {
          edges: [
            {
              node: { title, routeSlug, published, contentBlocks, source },
            },
          ],
        },
      },
    } = this.props

    const pageSchema = {
      title,
      slug: routeSlug.substring(1),
      abstract: title,
      breadcrumbs: [
        { title: 'Homepage', slug: '' },
        { title: 'News', slug: '/news' },
        { title, slug: routeSlug.substring(1) },
      ],
    }

    return (
      <NewsPageWrapper pageSchema={pageSchema} {...this.props}>
        <div className="container">
          <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
          <h1>{title}</h1>
          {map(contentBlocks, (block) => {
            const { type, content } = block
            let returnThis = <Fragment />

            if (type === 'text') {
              returnThis = <p style={{}}>{content}</p>
            }

            return returnThis
          })}
        </div>
        <aside>
          <p>
            <strong>PUBLISHED</strong>
            <br />
            {formattedDate(published)}
          </p>
          <p>
            <strong>SOURCE</strong>
            <br />
            <Link to={source}>Read more...</Link>
          </p>
        </aside>
      </NewsPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  })
  // dispatch => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
