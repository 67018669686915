// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import classNames from 'classnames'

import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query NewsPageWrapperQuery {
    allNewsJson {
      edges {
        node {
          title
          routeSlug
          published
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** UpdatePageWrapper */
const UpdatePageWrapper = ({ children, ...rest }) => {
  const { uri, className } = rest

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const {
          allNewsJson: { edges },
        } = data

        const nodes = map(edges, 'node')
        const ordered = orderBy(nodes, ['published', 'asc'])

        return (
          <StandardPageWrapper
            {...rest}
            className={classNames(className, 'news-page', 'explorer')}
          >
            {children}
          </StandardPageWrapper>
        )
      }}
    />
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default UpdatePageWrapper
